import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetContactUserDataQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetContactUserDataQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    hasEverPaid?: boolean | null;
  } | null;
};

export const GetContactUserDataDocument = gql`
  query getContactUserData {
    user {
      id
      hasEverPaid
    }
  }
`;

/**
 * __useGetContactUserDataQuery__
 *
 * To run a query within a React component, call `useGetContactUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactUserDataQuery,
    GetContactUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactUserDataQuery,
    GetContactUserDataQueryVariables
  >(GetContactUserDataDocument, options);
}
export function useGetContactUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactUserDataQuery,
    GetContactUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactUserDataQuery,
    GetContactUserDataQueryVariables
  >(GetContactUserDataDocument, options);
}
export type GetContactUserDataQueryHookResult = ReturnType<
  typeof useGetContactUserDataQuery
>;
export type GetContactUserDataLazyQueryHookResult = ReturnType<
  typeof useGetContactUserDataLazyQuery
>;
export type GetContactUserDataQueryResult = Apollo.QueryResult<
  GetContactUserDataQuery,
  GetContactUserDataQueryVariables
>;
