import { chatClient } from 'lib/chat/ChatClient';
import { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';

export const useChat = () => {
  const [{ variationKey: chatVariationKey }] = useDecision('dbx_zoom_chat');

  useEffect(() => {
    if (!chatVariationKey || chatVariationKey === 'off') {
      chatClient.enableChat();
    } else {
      const checkForChatClient = async () => {
        if (
          /* eslint-disable no-underscore-dangle */
          window._DBX_ZOOM_CHAT_CLIENT &&
          typeof window._DBX_ZOOM_CHAT_CLIENT.showFormSwiftChat === 'function'
        ) {
          await window._DBX_ZOOM_CHAT_CLIENT.showFormSwiftChat();
          /* eslint-enable no-underscore-dangle */
        } else {
          setTimeout(checkForChatClient, 100);
        }
      };

      checkForChatClient();
    }
  }, [chatVariationKey]);
};
